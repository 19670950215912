<template>
  <b-modal
    id="cancel-issue-nf-modal"
    size="md"
    hide-header
    hide-footer
    content-class="modal__container"
  >
    <div class="modal-header">
      <div class="modal-title">Cancelamento NFS-e</div>
      <span class="icon-box">
        <Close class="icon stroke" @click="closeModal" />
      </span>
    </div>

    <p class="p-3 modal__content">
      Ao cancelar essa nota fiscal,
      <span>essa ação não poderá ser desfeita</span>. Deseja realmente cancelar
      {{ nfData.numero ? `a nota de nº ${nfData.numero}` : 'essa nota' }}?
    </p>

    <div class="my-4 d-flex justify-content-end">
      <b-button variant="outline-primary" class="mr-3" @click="closeModal">
        Cancelar
      </b-button>

      <b-button variant="primary" @click="cancelIssue">Confirmar</b-button>
    </div>
  </b-modal>
</template>

<script>
import api from '@/modules/nfe/api'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'CancelIssueNfModal',
  props: {
    nfData: Object
  },
  components: {
    Close: () => import('@/assets/icons/close.svg')
  },
  data() {
    return {
      clinic: getCurrentClinic()
    }
  },
  methods: {
    async cancelIssue() {
      const isLoading = this.$loading.show()

      try {
        await api.deleteNF(this.nfData.id)

        await this.reloadAllNFs()
        this.$toast.success('Nota excluída com sucesso')
      } catch (error) {
        await this.reloadAllNFs()
        this.$toast.error('Houve um erro ao tentar cancelar a Nota Fiscal. Por favor, tente novamente em alguns minutos. Se o erro persistir, contate o suporte.')
        console.error(error)
      } finally {
        isLoading.hide()
        this.closeModal()
      }
    },
    async reloadAllNFs() {
      const { data } = await api.getAllNFs(
          this.clinic.id,
          '',
          '',
          '',
          '',
          '',
          ''
      )

      this.$store.dispatch('nfe/updateNfTableData', data.data)
    },
    closeModal() {
      this.$bvModal.hide('cancel-issue-nf-modal')
    }
  }
}
</script>

<style lang="scss">
#cancel-issue-nf-modal {
  .modal-title {
    font-size: 18px;
    font-weight: 600;
    font-family: 'Nunito Sans';
    color: var(--type-active);
  }

  .icon-box {
    width: 24px;

    svg {
      fill: var(--neutral-500);
    }
  }

  .modal__container {
    margin-top: 14rem;
  }

  .modal__content {
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Nunito Sans';
    color: var(--type-active);

    > span {
      font-weight: 700;
    }
  }
}
</style>
